const FooterComponent = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-col">
          <span style={{ fontWeight: "bold", fontSize: "22px" }}>
            Primary Pages
          </span>

          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/#portfolio">Portfolio</a>
            </li>
            <li>
              <a href="/blogs">Blog</a>
            </li>
            <li>
              <a href="#contacts">Contact</a>
            </li>
          </ul>
        </div>

        <div className="footer-col">
          <span style={{ fontWeight: "bold", fontSize: "22px" }}>
            Connect with me
          </span>

          <div className="about-store" style={{ color: "white" }}>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="tel:+91 880-046-3103"
                  style={{ boxShadow: "none" }}
                >
                  <i data-feather="phone"></i> Phone number : +91 880-046-3103
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="mailto:manishgandotra@icloud.com"
                  style={{ boxShadow: "none" }}
                >
                  <i data-feather="mail"></i> Email : manishgandotra@icloud.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom" style={{ padding: "25px" }}>
        <p style={{ color: "white", fontSize: "15px" }}>
          &copy; 2024 Manishgandotra.com. All rights reserved.
        </p>
      </div>
    </>
  );
};
export default FooterComponent;
