import { Grid } from "@mui/material";

const whatIdo = [
  {
    title: "UI Development",
    description: `I excel in UI development, utilizing my proficiency in
                      HTML, CSS, Bootstrap, MUI, Antd, and UI/UX principles.
                      With a strong emphasis on design aesthetics and a
                      dedication to enhancing user experiences, I craft visually
                      compelling interfaces that guarantee smooth navigation.
                      Allow me to breathe vitality into your projects with
                      visually stimulating designs and user-friendly
                      functionality.`,
  },
  {
    title: "Project Management",
    description: `I specialize in leading and managing complex projects from
                      inception to completion, ensuring they are delivered on
                      time, within scope, and within budget. My expertise lies
                      in coordinating cross-functional teams, developing
                      comprehensive project plans, and implementing effective
                      risk management strategies. I excel in fostering
                      collaboration among team members and stakeholders,
                      ensuring clear communication and alignment with project
                      goals.`,
  },
  {
    title: "Front-end Development",
    description: `I specialize in front-end development, utilizing my
                      expertise in React and javascript. With a keen eye for
                      design and a focus on user experience, I create engaging
                      interfaces that ensure seamless navigation. Let me bring
                      your projects to life with intuitive functionality and
                      captivating visuals.`,
  },
  {
    title: "Back-end Development",
    description: `I have a strong proficiency in backend development, with a
                      focus on utilizing my skills in TypeScript, JavaScript
                      (Node.js), and platforms such as Express.js. My expertise
                      lies in crafting resilient functionalities and optimizing
                      data handling to create scalable solutions that drive your
                      applications forward. Entrust me with managing the
                      intricacies of the backend, guaranteeing seamless and
                      secure operation for your projects.`,
  },
  {
    title: "DevOps(AWS)",
    description: `I specialize in implementing and managing robust DevOps
                      practices on AWS, focusing on automating infrastructure,
                      enhancing system reliability, and optimizing performance.
                      My expertise includes continuous integration/continuous
                      deployment (CI/CD), infrastructure as code (IaC), and
                      cloud-native solutions, ensuring seamless deployment and
                      scalability of applications.`,
  },
  {
    title: "Mobile-App Development",
    description: `I specialize in Mobile-App development, utilizing my
                      expertise in React-Native and javaScript. I bring a keen
                      eye for design and a focus on user experience to create
                      engaging interfaces that ensure seamless navigation. Let
                      me bring your projects to life with intuitive
                      functionality and captivating visuals.`,
  },
];
const WhatIdoComponent = () => {
  return (
    <>
      <div
        className="rn-service-area rn-section-gap section-separator rn-service-area responsive-padding"
        id="whatIdo"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-left"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="100"
                data-aos-once="true"
              >
                <h2 className="title">What I Do</h2>
              </div>
            </div>
          </div>
          <div style={{ justifyContent: "center", marginTop: "10px" }}>
            <div
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <Grid container spacing={2}>
                {whatIdo?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.title}>
                    <div className="rn-service">
                      <div className="inner">
                        <div className="content">
                          <h4 className="title">{item.title}</h4>
                          <p className="description">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhatIdoComponent;
