import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter, useRoutes } from "react-router-dom";
import Main from "./pages/main";
import BloggerComponent from "./pages/blogs";
import CurrentBlog from "./pages/currentBlog";
function App() {
  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;

function AppRoutes() {
  const routes = [
    { path: "/", element: <Main /> },
    { path: "/blogs", element: <BloggerComponent /> },
    { path: "/blog/:id", element: <CurrentBlog /> },

    { path: "*", element: <></> },
  ];

  const element = useRoutes(routes);

  return element;
}
