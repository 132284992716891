import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";

export default function ModalComponent({ item }: any) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <a
        className="rn-btn"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        <span>View details </span>
      </a>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          style={{ overflow: "scroll" }}
        >
          <span style={{ fontSize: "25px", fontWeight: "bold" }}>
            {item.title}
          </span>
          <span style={{ fontSize: "15px" }}>{item.description}</span>
          <img src={item.image} />

          {item.roles?.map((item: string) => (
            <li key={item} style={{ fontSize: "14px" }}>
              {item}
            </li>
          ))}
          <div className="container">
            <div className="content">
              <a
                className="rn-btn"
                onClick={() => setOpen(false)}
                style={{
                  boxShadow: "none",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                <span>Close</span>
              </a>
            </div>
          </div>
        </ModalDialog>
      </Modal>
    </>
  );
}
