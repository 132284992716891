import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallIcon from "@mui/icons-material/Call";

const ImageComponent = () => {
  const path = window.location.pathname.replaceAll("/", "");

  return (
    <>
      <div id="home" className="rn-slider-area main-bg">
        <div
          className="slide slider-style-1"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.607)",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="order-2 order-lg-1 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="content">
                  <div className="header-right responsive-padding">
                    <div className="inner">
                      <h1 className="title">
                        <span style={{ color: "white" }}>Hi,</span>
                        <br />
                        <span style={{ color: "white", marginBottom: "10px" }}>
                          I’m Manish Gandotra
                        </span>{" "}
                        <br />
                        <span style={{ color: "white" }}>
                          a Professional Coder.
                        </span>
                      </h1>
                    </div>
                    {!path && (
                      <a
                        className="rn-btn"
                        target="_blank"
                        style={{ boxShadow: "none" }}
                        href="./resume/manish_resume.pdf"
                      >
                        {<span>DOWNLOAD RESUME</span>}
                      </a>
                    )}
                    &nbsp;
                    {!path && (
                      <a
                        className="rn-btn"
                        href="#about"
                        style={{ boxShadow: "none", marginTop: "20px" }}
                      >
                        <span>About me</span>
                      </a>
                    )}
                    <div className="close-menu d-block">
                      <span className="closeTrigger">
                        <i data-feather="x"></i>
                      </span>
                    </div>
                  </div>

                  <div className="social-share-inner-left responsive-padding">
                    <br />
                    <span className="findwithme">FIND WITH ME</span>
                    <br />
                    <ul
                      className="social-share d-flex liststyle"
                      style={{ display: "flex" }}
                    >
                      <li className="github">
                        <a
                          href="https://github.com/manishgandotracoder"
                          target="_blank"
                          style={{ boxShadow: "none" }}
                        >
                          <GitHubIcon style={{ fontSize: "60px" }} />
                        </a>
                      </li>
                      <li className="linkedin">
                        <a
                          target="_blank"
                          style={{ boxShadow: "none" }}
                          href="https://www.linkedin.com/in/manish-gandotra-b53413b8/"
                        >
                          <LinkedInIcon style={{ fontSize: "60px" }} />
                        </a>
                      </li>
                      <li className="phone">
                        <a
                          target="_blank"
                          href="tel:+91 880-046-3103"
                          style={{ boxShadow: "none" }}
                        >
                          <CallIcon style={{ fontSize: "60px" }} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImageComponent;
