import React, { useEffect, useState } from "react";
import ContactComponent from "../components/contact";
import FooterComponent from "../components/footer";
import { useNavigate, useParams } from "react-router-dom";
import ImageComponent from "../components/image";
import { blogs } from "../jsons/blogs";
import DrawerComponent from "../container/drawer";
import {
  ContactMailTwoTone,
  Filter2Outlined,
  HomeOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu"; // Import the menu icon

function CurrentBlog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentBlog, setCurrentBlog] = useState<any>();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    id && setCurrentBlog(blogs.find((item) => item.id === +id));
  }, [id]);

  return (
    <div className="App">
      <header className="rn-header haeder-default black-logo-version header--fixed header--sticky">
        <div className="header-wrapper rn-popup-mobile-menu m--0 row align-items-center">
          <div className="col-lg-2 col-6">
            <div className="header-left">
              <div className="icon">
                <MenuIcon
                  className="header-right "
                  style={{ cursor: "pointer", fontSize: "30px" }}
                  onClick={toggleSidebar}
                />
              </div>
              <div className="icon-image logo" onClick={() => navigate("/")}>
                <img
                  className="imager"
                  style={{ height: "60px", borderRadius: "  50%" }}
                  src="/main/manish.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-6">
            <div className="header-center">
              <nav
                id="sideNav"
                className="mainmenu-nav navbar-example2 d-none d-xl-block"
              >
                <ul className="primary-menu nav nav-pills">
                  <li className="nav-item">
                    <a className="nav-link smoth-animation active" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link smoth-animation active"
                      href="/blogs/"
                    >
                      Blogs
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#contacts">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="header-right">
                <div className="hamberger-menu d-block d-xl-none">
                  <i id="menuBtn" className="feather-menu humberger-menu"></i>
                </div>
                <div className="close-menu d-block">
                  <span className="closeTrigger">
                    <i data-feather="x"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <DrawerComponent
            isSidebarOpen={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
            values={[
              {
                title: "Home",
                icon: <HomeOutlined style={{ fontSize: "20px" }} />,
                redirect: "/",
              },
              {
                title: "Blogs",
                icon: <Filter2Outlined style={{ fontSize: "20px" }} />,
                redirect: "/blogs",
              },

              {
                title: "Contact",
                icon: <ContactMailTwoTone style={{ fontSize: "20px" }} />,
                redirect: "#contacts",
              },
            ]}
          />
        </div>
      </header>

      <div className="popup-mobile-menu">
        <div className="inner">
          <div className="menu-top">
            <div className="menu-header">
              <a className="logo" href="index.html">
                <img
                  style={{ height: "60px", borderRadius: "  50%" }}
                  src="/main/manish.png"
                  alt="logo"
                />
              </a>
              <div className="close-button">
                <button className="close-menu-activation close">
                  <i data-feather="x"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="content">
            <ul className="primary-menu nav nav-pills">
              <li className="nav-item">
                <a className="nav-link smoth-animation active" href="/blogs/">
                  Blogs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#contacts">
                  Contact
                </a>
              </li>
            </ul>
            <div className="social-share-style-1 mt--40">
              <span className="title">find with me</span>
              <ul className="social-share d-flex liststyle">
                <li className="linkedin">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/manish-gandotra-b53413b8/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-linkedin"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </a>
                </li>

                <li className="phone">
                  <a target="_blank" href="tel:+91 880-046-3103">
                    <i data-feather="phone"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="template-color-1 spybody white-version"
        data-spy="scroll"
        data-target=".navbar-example2"
        data-offset="70"
      >
        <ImageComponent />
        <main className="main-page-wrapper">
          <div className="responsive-padding">
            <p
              style={{
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: "20px",
              }}
            >
              About
            </p>
            <span>{currentBlog?.description}</span>
            <br />
            <br />
            <div>
              <img
                src={currentBlog?.image}
                style={{ width: "50%", minWidth: "400px" }}
              />
            </div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: currentBlog?.details }} />
          </div>

          <ContactComponent />
          <FooterComponent />
        </main>
      </div>
    </div>
  );
}

export default CurrentBlog;
