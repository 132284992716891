import { Grid, Slider } from "@mui/material";
import "./styles.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const skillsData = [
  { name: "REACT", value: 90 },
  { name: "NODE", value: 90 },
  { name: "JAVASCRIPT", value: 80 },
  { name: "TYPESCRIPT", value: 80 },
  { name: "MYSQL", value: 75 },
  { name: "AWS", value: 75 },
  { name: "CSS", value: 85 },
  { name: "HTML", value: 85 },
  { name: "JEST", value: 80 },
  { name: "ENZYME", value: 80 },
  { name: "CHAI", value: 80 },
  { name: "MOCHA", value: 80 },
  { name: "MONGODB", value: 85 },
  { name: "POSTGRESQL", value: 85 },
  { name: "REACT NATIVE", value: 75 },
  { name: "SWAGGER", value: 85 },
  { name: "EXPRESS", value: 90 },
];
const AboutComponent = () => {
  const [read, setRead] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="rn-service-area rn-section-gap section-separator"
        id="about"
      >
        <div className="container main_head">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-left"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="100"
                data-aos-once="true"
              >
                <h2 className="title">About Me</h2>
                <div className="inner">
                  <div className="content">
                    <p className="content">
                      I bring around 8 years of experience as a Full Stack
                      Developer, during which I've navigated a variety of
                      projects, each presenting unique opportunities for
                      innovation and problem-solving. My journey has seen me
                      contribute to projects both individually and as part of a
                      team, always with a focus on delivering high-quality,
                      scalable solutions that meet and exceed client
                      expectations.
                      {!read && (
                        <span
                          onClick={() => setRead(!read)}
                          className="coloring"
                          style={{ cursor: "pointer" }}
                        >
                          {"  "}Continue reading ...
                        </span>
                      )}
                      {read && (
                        <>
                          {" "}
                          I've cultivated proficiency in frontend and backend
                          development, leveraging technologies like React,
                          Node.js, TypeScript, and Next.js to craft dynamic user
                          interfaces and robust server-side logic. <br />
                          <br />
                          In terms of cloud computing and database management, I
                          have honed my skills extensively. I've successfully
                          deployed and maintained cloud-based solutions using
                          AWS services, ensuring scalability, reliability, and
                          security for the applications I've worked on.
                          Additionally, my expertise extends to database
                          management with MongoDB, MySQL, and PostgreSQL. I've
                          implemented efficient data modeling, optimization, and
                          performance tuning strategies, ensuring smooth data
                          storage and retrieval processes for the applications
                          I've developed.
                          <br />
                          <br />
                          Moreover, I am open to relocation opportunities and
                          eager to bring my skills and dedication to new
                          challenges and environments. With a proven track
                          record of success in various projects and a passion
                          for continuous learning and growth, I am confident in
                          my ability to adapt to new settings and contribute
                          effectively to any team's objectives.
                        </>
                      )}
                      {read && (
                        <span
                          className="coloring"
                          onClick={() => setRead(!read)}
                          style={{ cursor: "pointer" }}
                        >
                          {"  "} Read Less...
                        </span>
                      )}
                    </p>

                    <h2 className="title" id="show-skills-btn">
                      <span>My skills</span>
                    </h2>

                    <Grid container spacing={2} mt={2}>
                      {skillsData?.map((item) => (
                        <Grid item xs={12} sm={6} md={4} key={item.name} mt={2}>
                          <span>{item.name}</span>

                          <div className="progress-container">
                            <div
                              className="progress-bar"
                              style={{ width: `${item.value}%` }}
                            ></div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutComponent;
