import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ModalComponent from "../modal";

export default function PortfolioCardComponent({ item }: any) {
  return (
    <>
      <Card
        style={{
          height: "100%",
          borderRadius: "12px",
          boxShadow: "5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff",
        }}
      >
        <CardMedia
          component="img"
          image={item.image}
          style={{ height: "200px" }}
          alt="Paella dish"
        />
        <CardContent>
          <Typography
            variant="h5"
            sx={{ color: "text.secondary" }}
            className="limit-text-header-3"
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "#525252",
              }}
            >
              {item.title}
            </span>
            <br />
            {item.description}
          </Typography>
          <br />

          <ModalComponent item={item} />
        </CardContent>
      </Card>
    </>
  );
}
