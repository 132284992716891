import React, { useState, useEffect } from "react";
import AboutComponent from "../components/about";
import WhatIdoComponent from "../components/WhatIdo";
import PortfolioComponent from "../components/portfolio";
import ResumeComponent from "../components/resume";
import ContactComponent from "../components/contact";
import FooterComponent from "../components/footer";
import BlogComponent from "../components/blog";
import ImageComponent from "../components/image";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu"; // Import the menu icon
import CloseIcon from "@mui/icons-material/Close"; // Import the close icon
import "./styles.css";
import {
  ContactMailTwoTone,
  FileOpenOutlined,
  FilePresentOutlined,
  Filter2Outlined,
  HomeOutlined,
  InfoOutlined,
  InfoRounded,
} from "@mui/icons-material";
import DrawerComponent from "../container/drawer";
function Main() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="App">
      <header className="rn-header haeder-default black-logo-version header--fixed header--sticky">
        <div className="header-wrapper rn-popup-mobile-menu m--0 row align-items-center">
          <div className="col-lg-2 col-6">
            <div className="header-left">
              <div className="icon">
                <MenuIcon
                  className="header-right "
                  style={{ cursor: "pointer", fontSize: "30px" }}
                  onClick={toggleSidebar}
                />
              </div>
              <div className="icon-image logo" onClick={() => navigate("/")}>
                <img
                  className="imager"
                  style={{ height: "60px", borderRadius: "  50%" }}
                  src="/main/manish.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>

          {/* Navigation Section (Visible on large screens) */}
          <div className="col-lg-9 d-none d-lg-block">
            <div className="header-center">
              <nav id="sideNav" className="mainmenu-nav navbar-example2">
                <ul className="primary-menu nav nav-pills">
                  <li className="nav-item">
                    <a className="nav-link smoth-animation active" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#whatIdo">
                      What I do
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#resume">
                      Resume
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#blog">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link smoth-animation" href="#contacts">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <DrawerComponent
            isSidebarOpen={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
            values={[
              {
                title: "Home",
                icon: <HomeOutlined style={{ fontSize: "20px" }} />,
                redirect: "#",
              },
              {
                title: "About",
                icon: <InfoOutlined style={{ fontSize: "20px" }} />,
                redirect: "#about",
              },
              {
                title: "What I do",
                icon: <InfoRounded style={{ fontSize: "20px" }} />,
                redirect: "#whatIdo",
              },
              {
                title: "Portfolio",
                icon: <FileOpenOutlined style={{ fontSize: "20px" }} />,
                redirect: "#portfolio",
              },
              {
                title: "Resume",
                icon: <FilePresentOutlined style={{ fontSize: "20px" }} />,
                redirect: "#resume",
              },
              {
                title: "Blog",
                icon: <Filter2Outlined style={{ fontSize: "20px" }} />,
                redirect: "#blog",
              },
              {
                title: "Contact",
                icon: <ContactMailTwoTone style={{ fontSize: "20px" }} />,
                redirect: "#contacts",
              },
            ]}
          />
          {/* Hamburger Menu for Mobile View */}
        </div>
      </header>

      {/* Sidebar Menu (Overlay for small screens) */}
      <div className={`popup-mobile-menu ${isSidebarOpen ? "active" : ""}`}>
        <div className="inner">
          <div className="menu-top">
            <div className="menu-header">
              <a className="logo" href="index.html">
                <img
                  style={{ height: "60px", borderRadius: "50%" }}
                  src="/main/manish.png"
                  alt="logo"
                />
              </a>
              <div className="close-button">
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={toggleSidebar}
                />
              </div>
            </div>
          </div>
          <div className="content">
            <ul className="primary-menu nav nav-pills">
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#whatIdo">
                  What I do
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#portfolio">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#resume">
                  Resume
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#blog">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smoth-animation" href="#contacts">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="template-color-1 spybody white-version"
        data-spy="scroll"
        data-target=".navbar-example2"
        data-offset="70"
      >
        <ImageComponent />
        <AboutComponent />
        <main className="main-page-wrapper">
          <WhatIdoComponent />
          <PortfolioComponent />
          <ResumeComponent />
          <BlogComponent />
          <ContactComponent />
          <FooterComponent />
        </main>
      </div>
    </div>
  );
}

export default Main;
