import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function BlogCardComponent({ item, onlyImage }: any) {
  const navigate = useNavigate();
  return (
    <>
      <Card
        style={{
          height: "100%",
          borderRadius: "12px",
          boxShadow: "5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff",
          cursor: "pointer",
        }}
        // onclick={navigate(`/blog/${item.id}`)}
      >
        <CardHeader
          title={
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "#525252",
              }}
            >
              {item.title}
            </span>
          }
        />
        <CardMedia
          component="img"
          image={item.image}
          style={{ height: "200px" }}
          onClick={() => {
            navigate(`/blog/${item.id}`);
          }}
        />
        {!onlyImage && (
          <CardContent>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "#525252",
              }}
            >
              {item.title}
            </span>
            <Typography
              variant="h5"
              sx={{ color: "text.secondary" }}
              className="limit-text-header-3"
            >
              {item.description}
            </Typography>
            <br />
            <a
              className="rn-btn"
              style={{ cursor: "pointer" }}
              href={`/blog/${item.id}`}
            >
              <span>View details </span>
            </a>
          </CardContent>
        )}
      </Card>
    </>
  );
}
