import { Grid } from "@mui/material";
import { useState } from "react";
import "./styles.css";
import ModalComponent from "../../container/modal";

const ResumeComponent = () => {
  const [view, setView] = useState("education");

  return (
    <>
      <div
        className="rn-resume-area rn-section-gap section-separator responsive-padding"
        id="resume"
      >
        <div className="container">
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2 className="title">My Resume</h2>
              </div>
            </div>
          </div>
          <Grid container className="grid_container">
            <Grid item xs={12} sm={6} md={4}>
              <div
                onClick={() => setView("education")}
                className={`tabber tabber-left ${
                  view === "education" ? "tabber-left-active" : ""
                }`}
              >
                <span>Education</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div
                onClick={() => setView("experience")}
                className={`tabber ${
                  view === "experience" ? "tabber-active" : ""
                }`}
              >
                <span>Experience</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div
                onClick={() => setView("certification")}
                className={`tabber tabber-right ${
                  view === "certification" ? "tabber-right-active" : ""
                }`}
              >
                <span>Certifications</span>
              </div>
            </Grid>
          </Grid>

          <div className="rn-nav-content tab-content" id="myTabContents">
            {view === "education" && (
              <div
                className="tab-pane show active fade single-tab-area"
                id="education"
              >
                <div
                  className="tab-pane show active fade single-tab-area"
                  id="education"
                  role="tabpanel"
                  aria-labelledby="education-tab"
                >
                  <div className="personal-experience-inner mt--40">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="content">
                          <span className="subtitle">2013 - 2017</span>
                          <h4 className="maintitle">Education</h4>
                          <div className="experience-list">
                            <div className="resume-single-list">
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>Bachelor of Technology</h4>
                                    <span>
                                      Punjab Technical University (2013 - 2017)
                                    </span>
                                  </div>
                                </div>
                                <p className="description">
                                  B.Tech in Computer Science and Engineering
                                  (CSE), skilled in programming (C, C++, Java,
                                  Python), software development, data
                                  structures, algorithms, and database
                                  management. Experienced in application
                                  development with strong problem-solving
                                  skills. Adaptable to emerging technologies. .
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {view === "experience" && (
              <div className="tab-pane fade single-tab-area" id="experience">
                <div
                  className="tab-pane fade"
                  id="experience"
                  role="tabpanel"
                  aria-labelledby="experience-tab"
                >
                  <div className="personal-experience-inner mt--40">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mt_md--60 mt_sm--60">
                        <div className="content">
                          <span className="subtitle">2017 - CURRENT</span>
                          <h4 className="maintitle">Job Experience</h4>
                          <div className="experience-list">
                            <div className="resume-single-list">
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>TERMGRID</h4>
                                    <span>TECHNICAL LEAD</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>FEB 2024 - Present</span>
                                  </div>
                                </div>
                                <p className="description">
                                  • Developed a robust debt management module
                                  using React.js, efficiently handling over 1
                                  million records, enhancing system scalability
                                  by 40%. <br />• Implemented a comprehensive
                                  suite of testing procedures (unit,
                                  integration, and end-to-end), reducing
                                  software bugs by 20% and boosting user
                                  satisfaction by 15%. <br />
                                  • Conducted performance tuning, improving load
                                  times by 30% and reducing server response time
                                  by 25%. <br />
                                  • Led Agile project progress as Scrum Master,
                                  boosting team productivity by 30% through
                                  effective sprint planning and backlog
                                  management. <br />
                                  • Integrated a CI/CD pipeline using Jenkins
                                  and Docker, decreasing deployment time by 40%
                                  and ensuring continuous delivery of
                                  high-quality code. <br />
                                  • Collaborated with the UX/UI team to redesign
                                  key user interfaces, resulting in a 25%
                                  increase in user engagement and a 10%
                                  reduction in bounce rates. <br />
                                  • Led the migration of legacy systems to
                                  microservices architecture, reducing technical
                                  debt by 35% and improving system
                                  maintainability. <br />
                                </p>
                              </div>
                            </div>
                            <div className="resume-single-list">
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>DELOITTE</h4>
                                    <span>DC CONSULTANT</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>Jul 2022 - FEB 2024</span>
                                  </div>
                                </div>
                                <p className="description">
                                  • Engineered the ‘Type Save’ feature,
                                  leveraging advanced cache memory optimization
                                  techniques to enhance website performance by
                                  50%, leading to faster page loads and improved
                                  user experience.
                                  <br />
                                  • Led the deployment of cloud infrastructure
                                  on AWS, increasing scalability and reliability
                                  by 40%, supporting the seamless handling of
                                  increased traffic and data loads.
                                  <br />
                                  • Optimized the deployment pipeline on Heroku,
                                  reducing deployment time by 30%, and
                                  introduced automated monitoring and rollback
                                  mechanisms to minimize downtime.
                                  <br />
                                  • Spearheaded Git version control management,
                                  implementing advanced branching strategies
                                  that improved project delivery timelines by
                                  25% and reduced merge conflicts by 20%.
                                  <br />
                                  • Mentored junior developers and conducted
                                  knowledge-sharing sessions, fostering a
                                  culture of continuous learning and improving
                                  the overall technical proficiency of the team.
                                  <br />
                                </p>
                              </div>
                            </div>
                            <div className="resume-single-list">
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>COGNIZANT</h4>
                                    <span>ASSOCIATE PROJECTS</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>May 2021 - Jul 2022</span>
                                  </div>
                                </div>
                                <p className="description">
                                  • Designed and implemented a high-performance
                                  website using React, Node.js, Nest.js, and
                                  GraphQL, enabling real-time tracking of over
                                  5000 websites, which improved decision-making
                                  efficiency by 27%.
                                  <br />
                                  • Automated email and ticket generation
                                  systems integrated with Okta Authentication,
                                  reducing resolution time by 40% and enhancing
                                  security by 15%.
                                  <br />
                                  • Deployed serverless applications using AWS
                                  Lambda, resulting in a 50% reduction in server
                                  costs and improved response times for user
                                  queries.
                                  <br />
                                  • Conducted code audits and refactoring
                                  sessions, improving code quality by 25% and
                                  reducing the number of post-release bugs by
                                  30%.
                                  <br />
                                  • Partnered with the DevOps team to implement
                                  containerization strategies using Docker and
                                  Kubernetes, enhancing deployment flexibility
                                  and environment consistency.
                                  <br />
                                </p>
                              </div>
                            </div>
                            <div className="resume-single-list">
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>INTELIZIGN ENGINEERING SERVICES</h4>
                                    <span>SOFTWARE ENGINEER</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>Aug 2020 - Apr 2021</span>
                                  </div>
                                </div>
                                <p className="description">
                                  • Conceptualized and built a real-time job
                                  portal using React.js, TypeORM, and Node.js,
                                  incorporating features like multi-role
                                  management and task handling, improving
                                  platform efficiency by 20%.
                                  <br />
                                  • Implemented clustering techniques to
                                  distribute workloads across multiple Node.js
                                  instances, achieving up to a 40% increase in
                                  response speed and system reliability.
                                  <br />
                                  • Designed and deployed a microservices
                                  architecture, ensuring high scalability,
                                  resilience, and maintainability, leading to a
                                  30% increase in system speed and reduced
                                  downtime.
                                  <br />
                                  • Integrated third-party APIs to enhance the
                                  job portal's functionality, resulting in a 15%
                                  increase in user retention and engagement.
                                  <br />
                                  • Conducted stress testing and performance
                                  analysis, identifying bottlenecks and
                                  optimizing system performance by 25%.
                                  <br />
                                  • Spearheaded the adoption of Agile
                                  methodologies within the team, leading to a
                                  20% reduction in project delivery times and
                                  improved collaboration.
                                  <br />
                                </p>
                              </div>
                            </div>
                            <div className="resume-single-list">
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>NATIONAL INFORMATICS CENTER</h4>
                                    <span>SOFTWARE APPLICATION ENGINEER</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>Jun 2017 - Apr 2020</span>
                                  </div>
                                </div>
                                <p className="description">
                                  • Led the development of a government bill
                                  management system using React.js and Node.js,
                                  streamlining operations and reducing
                                  processing costs by 30%.
                                  <br />
                                  • Enhanced complex SQL queries for bill
                                  creation and validation processes, improving
                                  transparency and decision-making efficiency by
                                  25%.
                                  <br />
                                  • Introduced a modular-based front-end
                                  architecture, facilitating easier scalability
                                  and maintainability, resulting in a 40%
                                  reduction in adaptation time for new features.
                                  <br />
                                  • Implemented role-based access control (RBAC)
                                  to enhance the security of the bill management
                                  system, reducing unauthorized access incidents
                                  by 15%.
                                  <br />
                                  • Developed comprehensive documentation and
                                  user manuals, improving onboarding efficiency
                                  for new users and reducing support requests by
                                  20%.
                                  <br />
                                  • Collaborated with government stakeholders to
                                  gather requirements and deliver customized
                                  solutions, improving overall client
                                  satisfaction by 30%.
                                  <br />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {view === "certification" && (
              <div
                className="tab-pane fade single-tab-area"
                id="certifications"
              >
                <div
                  className="tab-pane fade"
                  id="certifications"
                  role="tabpanel"
                  aria-labelledby="interview-tab"
                >
                  <div className="personal-experience-inner mt--40">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="content">
                          <h4 className="maintitle">Certifications</h4>
                          <div className="experience-list">
                            <div
                              data-aos="fade-up"
                              data-aos-duration="500"
                              data-aos-delay="300"
                              data-aos-once="true"
                              className="resume-single-list"
                            >
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>
                                      Meta Front-End Developer Professional
                                      Certificate
                                    </h4>
                                    <span>Meta</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>2023</span>
                                  </div>
                                </div>
                                <p className="description">
                                  This course teaches the skills to create
                                  responsive websites using HTML, CSS, and
                                  JavaScript. It covers React integration,
                                  Bootstrap CSS Framework, GitHub repositories,
                                  version control, coding interview strategies,
                                  problem-solving techniques, and portfolio
                                  project development for job interviews.
                                </p>
                              </div>
                            </div>
                            <div
                              data-aos="fade-up"
                              data-aos-duration="500"
                              data-aos-delay="500"
                              data-aos-once="true"
                              className="resume-single-list"
                            >
                              <div className="inner">
                                <div className="heading">
                                  <div className="title">
                                    <h4>Introduction to Web Development</h4>
                                    <span>University of California</span>
                                  </div>
                                  <div className="date-of-time">
                                    <span>2024</span>
                                  </div>
                                </div>
                                <p className="description">
                                  Introduction to Web Development by the
                                  University of California provides a
                                  comprehensive foundation in web development.
                                  Covering HTML, CSS, and JavaScript, this
                                  course equips students with essential skills
                                  to build interactive and visually appealing
                                  websites. Through hands-on projects and guided
                                  instruction, learners delve into key concepts
                                  like responsive design, accessibility, and
                                  best coding practices.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeComponent;
