import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";

type Anchor = "top" | "left" | "bottom" | "right";

export default function DrawerComponent({
  isSidebarOpen,
  setSidebarOpen,
  values,
}: {
  isSidebarOpen: boolean;
  setSidebarOpen: Function;
  values: {
    redirect: string;
    title: string;
    icon: any;
  }[];
}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    toggleDrawer("left", isSidebarOpen);
  }, [isSidebarOpen]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidebarOpen(open);
      setState({ ...state, [anchor]: open });
    };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {values.map((item) => (
          <ListItem key={item?.title} disablePadding>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <a
                style={{ color: "#303030", fontSize: "16px" }}
                href={item.redirect}
              >
                {item.title}
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <Drawer
          anchor={"left"}
          open={isSidebarOpen}
          onClose={toggleDrawer("left", false)}
        >
          <div className="icon-image logo" onClick={() => navigate("/")}>
            <img className="imager" src="/manish.png" alt="logo" />
          </div>
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
