import { Grid } from "@mui/material";
import PortfolioCardComponent from "../../container/cards";

const projects = [
  {
    image: "/portfolio/centiment.png",
    title: "Centiment",
    description: `Centiment enables market researchers and everyday businesses to create a survey and get answers from a better online panel of respondents.`,
    detailsLink: "#details3",
    website: "https://www.centiment.co/",
    technologies: [
      "React",
      "Node",
      "Typescript",
      "Javascript",
      "CSS",
      "HTML",
      "PostgreSQL",
      "AWS",
      "Next.js",
      "Bootstrap",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Developed a full-stack survey platform using React, Next.js, and TypeScript, ensuring a seamless user experience and efficient state management.",
      "Implemented server-side rendering (SSR) and static site generation (SSG) with Next.js, optimizing the performance and load times of survey pages.",
      "Designed and developed RESTful APIs with Node.js, Nest.js, and Express for handling survey submissions, user authentication, and data retrieval.",
      "Integrated PostgreSQL database for secure and scalable storage of survey responses and user data, ensuring data integrity and optimized query performance.",
      "Utilized AWS services (such as S3 and Lambda) for scalable cloud deployment, serverless functions, and efficient data handling in the cloud.",
      "Developed reusable UI components using React, CSS, HTML, and Bootstrap, enhancing the modularity and consistency of the user interface across the platform.",
      "Ensured code quality through comprehensive unit testing and integration testing using Chai and Mocha, achieving high code coverage and reducing bugs in production.",
      "Collaborated on cross-functional teams, facilitating agile development processes, and participating in sprint planning, code reviews, and continuous integration (CI) pipelines.",
      "Enhanced security and performance by optimizing API calls and implementing JWT-based user authentication.",
    ],
  },

  {
    image: "/portfolio/smart.png",
    title: "Smart",
    website: "https://global.smart.com/",
    description: `Smart EV Systems offers complete installation of an Electric Vehicle Charging System, specifically designed for your community, with no up-front costs.`,
    detailsLink: "#details6",
    technologies: [
      "AWS",
      "Node",
      "Typescript",
      "Javascript",
      "MongoDB",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Developed a back-end system using Node.js, Nest.js, and Express for managing EV charging installations and maintenance processes.",
      "Built and optimized APIs for seamless communication between the front-end, charging hardware, and the cloud back-end system.",
      "Leveraged AWS services for scalable deployment, using Lambda and S3 for serverless architecture and automated data processing.",
      "Utilized MongoDB to store and manage customer, installation, and charging data with a focus on performance and scalability.",
      "Ensured robust testing using Chai and Mocha, implementing automated tests to guarantee the reliability of the charging platform.",
      "Collaborated with cross-functional teams to streamline continuous integration and deployment pipelines on AWS.",
    ],
  },

  {
    image: "/portfolio/ehrms.png",
    title: "eHRMS",
    website: "https://hrms.punjab.gov.in/",
    description: `eHRMS, or Enterprise Human Resources Management System, helps government offices manage their human resources by maintaining an online database of employees.`,
    detailsLink: "#details8",
    technologies: ["Node", "Typescript", "Javascript", "PostgreSQL"],
    roles: [
      "Led the development of a government bill management system with React.js and Node.js, cutting processing costs by 30%.",
      "Leveraged Next.js for server-side rendering (SSR), significantly improving system performance and user experience.",
      "Optimized SQL queries, improving decision-making efficiency by 25%.",
      "Introduced modular front-end architecture, reducing adaptation time for new features by 40%.",
      "Implemented RBAC, reducing unauthorized access by 15%.",
      "Created documentation and user manuals, reducing support requests by 20% and improving onboarding efficiency.",
      "Collaborated with government stakeholders, boosting client satisfaction by 30%.",
    ],
  },
  {
    image: "/portfolio/careerAtlas.png",
    title: "Career Atlas",
    description: `CareerAtlas - a tech-driven talent partner building high-performing teams and helping candidates find fulfilling careers.`,
    detailsLink: "#details4",
    website: "https://www.careeratlas.in/",
    technologies: [
      "Node",
      "Typescript",
      "Javascript",
      "CSS",
      "HTML",
      "PostgreSQL",
      "AWS",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Built a real-time job portal with Next.js, React.js, TypeORM, and Node.js, boosting platform efficiency by 20%.",
      "Increased response speed and reliability by 40% using clustering techniques for Node.js instances.",
      "Designed a microservices architecture, improving system speed by 30% and reducing downtime.",
      "Integrated third-party APIs, increasing user retention and engagement by 15%.",
      "Optimized system performance by 25% through stress testing and performance analysis.",
      "Led Agile adoption, reducing project delivery times by 20% and enhancing team collaboration.",
    ],
  },
  {
    image: "/portfolio/medialeap.png",
    title: "Media Leap",
    website: "https://medialeap.com/",
    description: `Media Leap is a music streaming service that lets you upload, stream, and sync music across multiple devices.`,
    detailsLink: "#details7",
    technologies: [
      "React",
      "Node",
      "Typescript",
      "Javascript",
      "CSS",
      "HTML",
      "MongoDB",
      "Next.js",
      "Bootstrap",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Developed a scalable front-end using React and Next.js, ensuring seamless music streaming and synchronization across multiple devices.",
      "Leveraged Next.js for server-side rendering (SSR) and static site generation (SSG), enhancing platform load times and SEO.",
      "Implemented comprehensive unit and integration testing using Chai and Mocha to ensure code quality and system reliability.",
      "Collaborated with the team to deploy the platform using a continuous integration pipeline, improving deployment efficiency and product updates.",
      "Leveraged Next.js for server-side rendering (SSR) and static site generation (SSG), enhancing platform load times and SEO.",
      "Styled the platform using CSS, HTML, and Bootstrap to ensure a responsive and user-friendly interface.",
      "Designed and implemented RESTful APIs with Node.js, Nest.js, and Express to manage music uploads, streaming services, and user authentication",
    ],
  },
  {
    image: "/portfolio/inkind.png",
    title: "Inkind",
    website: "https://inkind.com/",
    description:
      "inKind Capital provides operators with the lowest cost of capital and drives new loyal, high-spending guests through your restaurant doors",
    detailsLink: "#details35",
    technologies: [
      "React",
      "Node",
      "Typescript",
      "Javascript",
      "CSS",
      "HTML",
      "MongoDB",
      "AWS",
      "Next.js",
      "Bootstrap",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Developed and Led customer management module with React, Next.js enhancing scalability by 40%.",
      "Implemented testing procedures, reducing bugs by 20% and increasing user satisfaction by 15%.",
      "Improved performance, cutting load times by 30% and server response by 25%.",
      "Boosted team productivity by 30% as Scrum Master through effective sprint planning.",
      "Integrated CI/CD with Jenkins and Docker, reducing deployment time by 40%.",
      "Collaborated on UI redesign, increasing user engagement by 25% and reducing bounce rates by 10%.",
      "Migrated legacy systems to microservices, cutting technical debt by 35% and improving maintainability.",
    ],
  },
  {
    image: "/portfolio/peco.png",
    title: "Peco CAP",
    website: "https://www.peco.com/",
    description:
      "PECO Customer Assistance Program (CAP) is a program to make your electric bill more affordable. PECO customers with monthly household incomes at 150% or below of the Federal Poverty Line qualify for CAP.",
    detailsLink: "#details2",
    technologies: [
      "Node",
      "Typescript",
      "Javascript",
      "CSS",
      "HTML",
      "AWS",
      "CosmoDB - Mongo",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Built a high-performance website with Next.js, React, Node.js, Nest.js, and GraphQL, enabling real-time tracking for 5,000+ websites and improving decision-making by 27%.",
      "Automated email and ticket systems with Okta, reducing resolution time by 40% and boosting security by 15%.",
      "Deployed serverless apps on AWS Lambda, cutting server costs by 50% and improving query response times.",
      "Improved code quality by 25% and reduced post-release bugs by 30% through code audits and refactoring.",
      "Implemented containerization with Docker and Kubernetes, enhancing deployment flexibility and consistency.",
    ],
  },
  {
    image: "/portfolio/biolifeplasma.png",
    title: "Biolife Plasma",
    website: "https://www.biolifeplasma.com/",
    description: `At BioLife, I worked on improving the donor and patient experience in plasma therapies. BioLife is a global leader in life-saving treatments for rare diseases, focused on innovation and top-tier service.`,
    detailsLink: "#details1",
    technologies: [
      "React",
      "Node",
      "Typescript",
      "Javascript",
      "CSS",
      "HTML",
      "PostgreSQL",
      "AWS",
      "Next.js",
      "Bootstrap",
      "Nest.js",
      "Express",
      "Chai",
      "Mocha",
    ],
    roles: [
      "Improved the donor and patient experience by developing a responsive front-end using React, Next.js, and TypeScript.",
      "Developed and optimized RESTful APIs with Node.js, Nest.js, and Express to enable efficient plasma collection and donor management.",
      "Enhanced platform scalability by implementing serverless architecture on AWS, ensuring cost-effective deployment and real-time data handling.",
      "Integrated PostgreSQL database to securely manage donor records and optimize performance.",
      "Implemented testing practices using Chai and Mocha to ensure platform reliability and reduce bugs.",
      "Designed reusable UI components with React, Bootstrap, and CSS to improve code maintainability and UI consistency.",
      "Collaborated with cross-functional teams to ensure smooth CI/CD pipelines and effective product delivery.",
    ],
  },
];

const PortfolioComponent = () => {
  return (
    <>
      <div
        className="rn-service-area rn-section-gap section-separator rn-service-area responsive-padding"
        id="portfolio"
      >
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-left"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="100"
              data-aos-once="true"
            >
              <h2 className="title">My Portfolio Projects</h2>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          {projects?.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.title} marginTop={2}>
              <PortfolioCardComponent item={item} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};
export default PortfolioComponent;
