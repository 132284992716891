import { Grid } from "@mui/material";
import BlogCardComponent from "../../container/cards/blog";
import { blogs } from "../../jsons/blogs";

const BlogComponent = () => {
  const path = window.location.pathname.replaceAll("/", "");

  return (
    <>
      <div
        id="blog"
        style={{ paddingBottom: "30px" }}
        className="rn-service-area rn-section-gap section-separator rn-service-area responsive-padding"
      >
        {(path === "blogs" && (
          <Grid container spacing={2}>
            {blogs?.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item?.title} marginTop={2}>
                <BlogCardComponent item={item} onlyImage={true} />
              </Grid>
            ))}
          </Grid>
        )) || (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="section-title text-left"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="100"
                  data-aos-once="true"
                >
                  <h2 className="title">My Blogs</h2>
                </div>
              </div>
            </div>
            <Grid container spacing={2}>
              <>
                {blogs?.slice(0, 3).map((item) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={item?.title}
                    marginTop={2}
                  >
                    <BlogCardComponent item={item} />
                  </Grid>
                ))}
                <div
                  style={{
                    textAlign: "right",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <a
                    className="rn-btn"
                    style={{ cursor: "pointer" }}
                    href={`/blogs`}
                  >
                    <span>View all blogs</span>
                  </a>
                </div>
              </>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};
export default BlogComponent;
